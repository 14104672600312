import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactPage = ({ data }, location) => {

  const siteTitle = data.site.siteMetadata.title

  return (

    <Layout title={siteTitle}>

        <Seo title="Contato" keywords={[`contato`, `design`, `ui`, `ux`, `front-end`]} />

        <header className="page-head post-content-body">

            <h1 className="page-head-title">
                <span role="img" aria-label="Megafone">📣</span><br />
                Mande um oi!
            </h1>

            <h2>
                Seu contato é muito importante, vou respondê-lo o mais breve possível!
            </h2>

        </header>


        <article className="post-content page-template" style={{paddingTop: '0px'}}>

            <div className="post-content-body">

                <form method="post" action="https://app.99inbound.com/api/e/Qc1yk3Ep">
                
                    <div
                        className="g-recaptcha"
                        data-sitekey="6Le8PdcgAAAAAODDbUYCtS4AAbhitN7nbJhXAEw6"
                    />

                    <label>
                        Nome
                        <input type="text" name="name" id="name" required/>
                    </label>

                    <label>
                        Email
                        <input type="email" name="email" id="email" required/>
                    </label>

                    <label>
                        Assunto
                        <input type="text" name="subject" id="subject" required/>
                    </label>

                    <label>
                        Mensagem
                        <textarea name="message" id="message" rows="5" required/>
                    </label>

                    <button type="submit">Enviar</button>

                    <div style={{position: 'absolute', left: '-5000px'}}>
                        <input type="checkbox" name="genetically_modified_azure_matte_journey" defaultValue={1} tabIndex={-1} autoComplete="no" />
                    </div>

                </form>
        
            
            </div>

        </article>

    </Layout>

  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage location={props.location} data={data} {...props} />
    )}
  />
)
